@import "~ng-zorro-antd/ng-zorro-antd.min.css";
@import "~ng-zorro-antd/style/index.min.css";
/* Import base styles */
@import "~ng-zorro-antd/button/style/index.min.css"; /* Import one component's styles */

// swiper bundle styles
@import '~swiper/swiper-bundle.min.css';

// swiper core styles
@import '~swiper/swiper.min.css';

/** Fonts **/

/* cyrillic-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v18/mem5YaGs126MiZpBA-UN_r8OX-hpOqc.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* cyrillic */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v18/mem5YaGs126MiZpBA-UN_r8OVuhpOqc.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* greek-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v18/mem5YaGs126MiZpBA-UN_r8OXuhpOqc.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}

/* greek */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v18/mem5YaGs126MiZpBA-UN_r8OUehpOqc.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}

/* vietnamese */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v18/mem5YaGs126MiZpBA-UN_r8OXehpOqc.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}

/* latin-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v18/mem5YaGs126MiZpBA-UN_r8OXOhpOqc.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v18/mem5YaGs126MiZpBA-UN_r8OUuhp.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v18/mem8YaGs126MiZpBA-UFWJ0bbck.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* cyrillic */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v18/mem8YaGs126MiZpBA-UFUZ0bbck.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* greek-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v18/mem8YaGs126MiZpBA-UFWZ0bbck.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}

/* greek */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v18/mem8YaGs126MiZpBA-UFVp0bbck.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}

/* vietnamese */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v18/mem8YaGs126MiZpBA-UFWp0bbck.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}

/* latin-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v18/mem8YaGs126MiZpBA-UFW50bbck.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v18/mem8YaGs126MiZpBA-UFVZ0b.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v18/mem5YaGs126MiZpBA-UN7rgOX-hpOqc.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* cyrillic */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v18/mem5YaGs126MiZpBA-UN7rgOVuhpOqc.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* greek-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v18/mem5YaGs126MiZpBA-UN7rgOXuhpOqc.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}

/* greek */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v18/mem5YaGs126MiZpBA-UN7rgOUehpOqc.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}

/* vietnamese */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v18/mem5YaGs126MiZpBA-UN7rgOXehpOqc.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}

/* latin-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v18/mem5YaGs126MiZpBA-UN7rgOXOhpOqc.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v18/mem5YaGs126MiZpBA-UN7rgOUuhp.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: "Chronica Pro";
  src: url("/assets/fonts/ChronicaPro-Regular.ttf") format("opentype");
  font-weight: normal;
}

@font-face {
  font-family: "Chronica Pro";
  src: url("/assets/fonts/ChronicaPro-Black.ttf") format("opentype");
  font-weight: bold;
}

@font-face {
  font-family: "Segoe UI";
  src: url("/assets/fonts/Segoe UI.ttf") format("opentype");
  font-weight: 300;
}

@font-face {
  font-family: "Segoe UI";
  src: url("/assets/fonts/Segoe UI Bold.ttf") format("opentype");
  font-weight: 700;
}

/** Css **/

* {
  box-sizing: border-box;
  font-family: "Chronica Pro", sans-serif;
  scroll-behavior: smooth;
  outline: 0;
}

.answer {
  word-break: break-word;
  p { margin-bottom: 0 !important; }
}

.no-team {
  p { margin-bottom: 0 !important; }

  font {
    background-color: transparent !important;
  }
}

html {
  font-size: 16px !important;
  color: var(--uni-contrast-500) !important;
  font-family: "Open Sans", sans-serif !important;

  @media (max-width: 1600px) {
    font-size: 15px !important;
  }
  @media (max-width: 1400px) {
    font-size: 14px !important;
  }
  @media (max-width: 1200px) {
    font-size: 13px !important;
  }
  @media (max-width: 768px) {
    font-size: 12px !important;
  }

  body {
    font-family: unset;
    font-size: unset;
    background-color: var(--uni-black-500);
    color: var(--uni-contrast-500);

    overflow-y: auto;

    min-height: unset !important;
    min-width: unset !important;
  }

  min-height: unset !important;
  min-width: unset !important;
}

ul.ant-rate {
  min-width: 5.9rem;
}

:root {
  --uni-color-blue-dark: #02122e;
  --uni-color-dark: #000115;
  --uni-color-orange-dark: #071421;
  --uni-color-orange: #0e243c;
  --uni-color-blue: #00aaf0;
  --uni-collor-dark-gray: #2e373e;
  --uni-color-almost-black: #090909;
  --uni-color-gray-light: #d9d0c7;
  --uni-color-gray: #edeff2;
  --uni-color-green: #d3fa00;
  --uni-color-pink: #f149ff;

  --uni-card-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  --uni-frame-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  --uni-nav-shadow: 0px 2px 1px rgba(0, 0, 0, 0.25);
  --uni-text-shadow: 0px 2px 1px rgba(0, 0, 0, 0.25);

  --navbar-width: 4.5rem;
  --page-width: calc(100vw - var(--navbar-width));

  --profile-padding-left: 5rem;

  --uni-free-course-primary: #ff8f00;
  --uni-free-course-secondary: #ff2e9e;
  --uni-free-course-dark: #812855;
  --uni-blue: #469EC3;
  --uni-red: #B72A28;
}

.cdk-global-scrollblock {
  position: static !important;
}

.d-flex {
  display: flex;

  @media (max-width: 1024px) {
    flex-direction: column;
  }
}

.flex-column {
  flex-direction: column;
}

.uni-modal {
  --background: var(--uni-black-500);
  --height: 45vh;
  --width: 20vw;
  --box-shadow: none;
  --border-radius: 7px;

  background-color: rgba(0, 0, 0, 0.6);

  @media (max-width: 1440px) {
    --width: 30vw;
  }

  @media (max-width: 1024px) {
    --width: 35vw;
  }

  @media (max-width: 768px) {
    --width: 50vw;
  }

  @media (max-width: 425px) {
    --width: 80vw;
  }
}

.loading-full-info {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  height: 90vh;
}

.d-none {
  display: none !important;
}

.mt-1 {
  margin-top: 1rem;
}

.mt-2 {
  margin-top: 2rem;
}

.pl-05 {
  padding-left: 0.5rem;
}

.pr-2 {
  padding-right: 2rem;
}

/** Safe Area **/

@supports (padding-top: 20px) {
  html {
    --ion-safe-area-top: var(--ion-statusbar-padding);
  }
}

@supports (padding-top: constant(safe-area-inset-top)) {
  html {
    --ion-safe-area-top: constant(safe-area-inset-top);
    --ion-safe-area-bottom: constant(safe-area-inset-bottom);
    --ion-safe-area-left: constant(safe-area-inset-left);
    --ion-safe-area-right: constant(safe-area-inset-right);
  }
}

@supports (padding-top: env(safe-area-inset-top)) {
  html {
    --ion-safe-area-top: env(safe-area-inset-top);
    --ion-safe-area-bottom: env(safe-area-inset-bottom);
    --ion-safe-area-left: env(safe-area-inset-left);
    --ion-safe-area-right: env(safe-area-inset-right);
  }
}

#wc-content {
  display: flex;
}

/** Animations **/

@keyframes enterAnim {
  0% {
    transform: translateX(-50vw);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes goUp {
  0% {
    transform: translateY(40vh) translateZ(0);
  }
  60% {
    transform: translateY(40vh) translateZ(0);
  }
  100% {
    transform: translateY(0) translateZ(0);
  }
}

@keyframes goRight {
  0% {
    transform: translateX(-2rem) translateZ(0);
  }

  100% {
    transform: translateY(0) translateZ(0);
  }
}

@keyframes fadeIn {
  0% {
    filter: opacity(0);
  }

  100% {
    filter: opacity(1);
  }
}

@keyframes fadeOut {
  0% {
    filter: opacity(1);
  }

  100% {
    filter: opacity(0);
  }
}

.ngx-ui-tour_backdrop {
  z-index: 350 !important;
}
